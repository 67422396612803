import { useTranslation } from "react-i18next";
import { LanguageIcon } from "@heroicons/react/24/outline";
const availableLanguages = [
  { icon: "🇮🇹", value: "it" },
  { icon: "🇫🇷", value: "fr" },
  { icon: "🇬🇧", value: "en" },
];

export default function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const onLanguageChange = (event) => {
    const newLanguage = event.target.value;
    var languageInstalled = availableLanguages.find((l) => {
      return l.value === newLanguage;
    });
    if (languageInstalled) {
      i18n.changeLanguage(newLanguage);
    }
  };

  return (
    <div className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-charcoal-gray hover:bg-soft-white hover:text-charcoal-gray">
      {/* <LanguageIcon
        className="h-6 w-6 shrink-0 text-charcoal-gray group-hover:text-charcoal-gray"
        aria-hidden="true"
      /> */}
      <select
        className="bg-transparent text-charcoal-gray border-none outline-none"
        id="language"
        name="language"
        onChange={onLanguageChange}
      >
        {availableLanguages.map((l) => {
          return (
            <option
              label={`${l.icon} ${l.value.toUpperCase()}`}
              value={l.value}
              key={l.value}
            ></option>
          );
        })}
      </select>
    </div>
  );
}
