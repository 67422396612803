// MessageList component
import React, { useEffect } from "react";
import Message from "./Message";

import { useTranslation } from "react-i18next";

const MessageList = ({ messages }) => {
  const messagesContainer = React.createRef();
  const { t } = useTranslation();
  useEffect(() => {
    const lastChildElement = messagesContainer.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  }, [messagesContainer]);
  return (
    <div
      ref={messagesContainer}
      className="flex flex-col overflow-y-scroll h-96 p-4"
    >
      {messages.length ? (
        messages.map((m, index) => <Message key={index} message={m} />)
      ) : (
        <span>{t("inbox.noMessages")}</span>
      )}
    </div>
  );
};

export default MessageList;
