import { useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";

const navigation = [{ name: "Features", href: "#" }];

const features = [
  {
    name: "Social Media",
    icon: ArrowPathIcon, // Replace with the actual icon component you want to use
    description:
      "Manage orders and loyalty over the most popular channels like WhatsApp, FB, Instagram, and TikTok.",
    href: "/features/social-media", // Replace with the actual link to the detailed feature page or documentation
  },
  {
    name: "Payment",
    icon: ArrowPathIcon, // Replace with the actual icon component you want to use
    description:
      "Integrate with popular local payment gateways, becoming a unique differentiator for payment services.",
    href: "/features/payment", // Replace with the actual link to the detailed feature page or documentation
  },
  {
    name: "Marketing",
    icon: ArrowPathIcon, // Replace with the actual icon component you want to use
    description:
      "A customer data platform available for all, allowing for personalized marketing activities.",
    href: "/features/marketing", // Replace with the actual link to the detailed feature page or documentation
  },
  {
    name: "AI Insights",
    icon: ArrowPathIcon, // Replace with the actual icon component you want to use
    description:
      "Leverage AI to gather insights, customer segmentation, and automatic responses such as food recommendations.",
    href: "/features/ai", // Replace with the actual link to the detailed feature page or documentation
  },
];

export default function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
      <button
        className="rounded-md bg-soft-white px-3 py-2 text-sm font-semibold text-charcoal-gray shadow-sm hover:bg-soft-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-charcoal-gray-600"
        onClick={() => loginWithRedirect()}
      >
        Log In
      </button>
    );
  };

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {/* <LoginButton /> */}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=charcoal-gray&shade=600"
                  alt=""
                /> */}
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">{/* <LoginButton /> */}</div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#FFA07A] to-[#FF6347] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-16 ">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Serve More, Stress Less. Direct Orders via
              <span className="bg-gradient-to-r from-whatsapp-start to-whatsapp-end bg-clip-text text-transparent">
                WhatsApp
              </span>
              !
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Embrace the power of direct orders. Boost your revenue and let
              your unique branding shine.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://forms.gle/bDAAL23F2iG7JRAw5"
                target="_blank"
                className="rounded-md bg-soft-white px-3.5 py-2.5 text-sm font-semibold text-charcoal-gray shadow-sm hover:bg-soft-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-charcoal-gray-600"
                rel="noreferrer"
              >
                Join the Beta
              </a>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-2xl">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
          <div className="text-center">
            <h4 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              Watch a demo:
            </h4>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <iframe
                id="ytplayer"
                type="text/html"
                width="640"
                height="360"
                src="https://www.youtube.com/embed/xT25TXm5uZg?autoplay=1&origin=http://www.ordertalks.com"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
        <div id="feature-list" className="py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-peachy-orange">
                Empower Your Restaurant
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-charcoal-gray sm:text-4xl">
                Connect directly with your customers on social media.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Offering a social storefront for the food industry. Seamlessly
                bridge the gap between food booking, payment gateways, and your
                customers.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
                {features.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-charcoal-gray">
                      <feature.icon
                        className="h-5 w-5 flex-none text-peachy-orange"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>
                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p className="flex-auto">{feature.description}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
