import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Landing from "./components/Landing";
import Dashboard from "./components/Dashboard";
import Sumup from "./components/Payment/SumUp";
import PaymentCompleted from "./components/Payment/Completed";
import Orders from "./components/Orders";
import OrdersHistory from "./components/OrdersHistory";
import Inbox from "./components/Inbox";
import { AuthenticationGuard } from "./components/authentication-guard";

// todo dashboard/{storeId}/orders
// todo dashboard/{storeId}/history
// todo dashboard/{storeId}/menu
// todo dashboard/{storeId}/messages0

const reload = () => window.location.reload();

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      {/* <Route path="/login" element={<Login />} /> */}
      <Route
        path="dashboard"
        element={<AuthenticationGuard component={Dashboard} />}
      >
        <Route index path="orders" element={<Orders />} />
        <Route path="inbox" element={<Inbox />} />
        <Route path="orders/history" element={<OrdersHistory />} />
        {/* <Route path="*" element={<Orders />} /> */}
      </Route>
      <Route path="order/checkout" element={<Sumup />} />
      <Route path="order/:orderId/completed" element={<PaymentCompleted />} />
      <Route path="beta-login" element={<Login />} />
      {/* Fallback Route */}
      <Route path="*" element={<Navigate to="/dashboard/orders" replace />} />
    </Routes>
  );
};

// I need to wrap the dashboard with context provider

export default App;
