import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const getConversationsByRestaurantId = async (jwt) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    };
    const response = await api.get(`/api/messages/conversations`, { headers });

    return response.data;
  } catch (error) {
    console.error("Error fetching conversations:", error.message);
    throw error; // You can re-throw the error if you want to handle it in the component
  }
};

const sendMessage = async (jwt, message, to) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    };
    console.log("API Send Message", message);
    const body = {
      message,
      to,
    };
    const response = await api.post(`/api/messages/send`, body, { headers });
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error.message);
    return null;
  }
};

const updateConversationUnreadMessages = async (
  jwt,
  conversationId,
  unreadMessages = false
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    };
    const response = await api.post(
      `/api/messages/conversations/${conversationId}/unread-messages`,
      { unreadMessages },
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating conversation unreadMessages:", error.message);
    return null;
  }
};

export {
  getConversationsByRestaurantId,
  sendMessage,
  updateConversationUnreadMessages,
};
