const colors = {
  inbound: "#ece5dd",
  outbound: "#dcf8c6",
};
const { DateTime } = require("luxon");
export default function Message({ message }) {
  const formatDate = (timestamp) => {
    // if date is today, show only hours and minute. Otherwise, show entire date and time
    const date = DateTime.fromISO(timestamp);
    const today = DateTime.local();
    if (date.hasSame(today, "day")) {
      return date.toLocaleString(DateTime.TIME_SIMPLE);
    } else {
      return date.toLocaleString(DateTime.DATETIME_MED);
    }
  };

  return (
    <div
      className="flex flex-col px-4 py-2 rounded-md bg-blue-500 text-charcoal-gray max-w-xs mx-2 my-1"
      style={{
        backgroundColor:
          message.direction === "inbound" ? colors.inbound : colors.outbound,
        color: "black",
        alignSelf: message.direction === "inbound" ? "flex-start" : "flex-end",
      }}
    >
      <span className="flex-1 ">{message.text}</span>
      <span className="flex-1 text-sm font-medium text-gray-500">
        {formatDate(message.timestamp)}
      </span>
    </div>
  );
}
