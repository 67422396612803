import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const getOrderByCheckoutId = async (checkoutId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    if (!checkoutId) {
      console.log("Error: orderId is null");
      return null;
    }
    const response = await api.get(`/api/orders/checkout/${checkoutId}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    console.error("Error getting order by id:", error.message);
    return null;
  }
};

const getOrderById = async (orderId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    if (!orderId) {
      console.log("Error: orderId is null");
      return null;
    }
    const response = await api.get(`/api/orders/${orderId}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error getting order by id:", error.message);
    return null;
  }
};

const getOrders = async (jwt) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    };
    const response = await api.get(`/api/orders`, {
      headers,
    });

    return response.data;
  } catch (error) {
    console.error("Error getting orders:", error.message);
    return null;
  }
};

const getOrdersHistory = async (jwt) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    };
    const response = await api.get(`/api/orders/history`, {
      headers,
    });

    return response.data;
  } catch (error) {
    console.error("Error getting orders:", error.message);
    return null;
  }
};

const setOrderCompleted = async (jwt, orderId, restaurantId, phoneNumber) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    };
    const response = await api.put(
      `/api/orders/${orderId}/completed`,
      {
        restaurantId,
        phoneNumber,
      },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error setting order to completed:", error.message);
    return null;
  }
};

const refundOrder = async (jwt, checkoutId, restaurantId, orderId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    };
    if (!checkoutId || !restaurantId) {
      console.log("Error: checkoutId or restaurantId is null");
      return null;
    }
    const response = await api.post(
      `/api/payments/sumup/refund/${checkoutId}`,
      { restaurantId, orderId },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error refunding order:", error.message);
    return null;
  }
};

export {
  getOrderByCheckoutId,
  getOrders,
  setOrderCompleted,
  getOrdersHistory,
  getOrderById,
  refundOrder,
};
