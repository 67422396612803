import { useCallback, useEffect, useRef } from "react";
import socketIOClient from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_IO_URL;

const useSocket = () => {
  const socketRef = useRef();
  const isConnectingRef = useRef(false);

  useEffect(() => {
    console.log("Use Effect Socket");

    // Destroys the socket reference when the connection is closed
    // return () => {
    //   console.log("Call Socket Disconnect");
    if (socketRef.current) socketRef.current.disconnect();
    // };
  }, []);

  const connect = useCallback((jwt) => {
    if (isConnectingRef.current) {
      console.log("Already Connecting");
      return;
    }
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      path: "/socket",
      auth: {
        token: jwt,
      },
    });
    isConnectingRef.current = true;
    socketRef.current.on("connect", () => {
      console.log("Socket Connected");
      isConnectingRef.current = false;
    });
    socketRef.current.on("disconnect", () => {
      console.log("Socket Disconnected");
    });
    socketRef.current.on("connect_error", (err) => {
      console.log("Socket Error", err);
    });
  }, []);

  const on = (eventName, callback) => {
    if (!socketRef.current) return;
    socketRef.current.on(eventName, callback);
  };

  const off = (eventName, callback) => {
    if (!socketRef.current) return;
    socketRef.current.off(eventName, callback);
  };

  const emit = (eventName, data) => {
    if (!socketRef.current) return;
    socketRef.current.emit(eventName, data);
  };

  return { connect, on, off, emit, socketRef };
};

export default useSocket;
