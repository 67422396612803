import React from "react";
import { useTranslation } from "react-i18next";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

const statuses = {
  Complete: "text-green-700 bg-green-50 ring-green-600/20",
  "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
  Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

const ConversationList = ({ conversations, setActiveConversation }) => {
  const { t } = useTranslation();
  if (conversations && conversations.length === 0) {
    return (
      <p className="p-4 text-gray-500">{t("inbox.noActiveConversations")}</p>
    );
  }

  /* conversation
  creationData,
  customerWhatsAppNumber,
  messages,
  restaurantWhatsAppNumber,

  */

  const formatProfileName = (conversation) => {
    if (conversation && conversation.profile) {
      return conversation.profile.name;
    }
    return conversation.customerWhatsAppNumber;
  };

  return (
    <div className="overflow-y-auto h-96">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Conversations
      </h3>
      {conversations.map((conversation) => (
        <div
          key={conversation.customerWhatsAppNumber}
          className="flex min-w-0 gap-x-4 mt-5"
          onClick={() => setActiveConversation(conversation)}
        >
          <img
            className="h-12 w-12 flex-none rounded-full bg-gray-50"
            src={
              process.env.PUBLIC_URL + "/images/whatsapp-profile-unknown.png"
            }
            alt="whatsapp profile unknown"
          />
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-gray-900">
              {formatProfileName(conversation)}
            </p>
            <p className="text-sm leading-5 text-gray-500 truncate">
              Phone: {conversation.customerWhatsAppNumber}
            </p>
          </div>

          <div className="flex shrink-0 items-center gap-x-4">
            <div className="hidden sm:flex sm:flex-col sm:items-end">
              {conversation.unreadMessages && (
                <span className="inline-flex items-center gap-x-1.5 rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                  <svg
                    className="h-1.5 w-1.5 fill-green-500"
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  1
                </span>
              )}
            </div>
            <ChevronRightIcon
              className="h-5 w-5 flex-none text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConversationList;
