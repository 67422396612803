import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  Bars3Icon,
  ShoppingCartIcon,
  ChatBubbleBottomCenterIcon,
  RocketLaunchIcon,
  FolderIcon,
  XMarkIcon,
  BellIcon,
  Cog6ToothIcon,
  ChevronDownIcon,
  LanguageIcon,
} from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardContext from "../context/DashboardContext";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import useConversations from "../hooks/useConversations";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const navigation = [
  // { name: "Inventory", href: "#", icon: FolderIcon, current: false },
  {
    name: "Inbox",
    href: "/dashboard/inbox",
    icon: ChatBubbleBottomCenterIcon,
    current: false,
  },
  {
    name: "Orders",
    href: "/dashboard/orders",
    icon: ShoppingCartIcon,
    current: true,
  },
  {
    name: "OrdersHistory",
    href: "/dashboard/orders/history",
    icon: FolderIcon,
    current: false,
  },
  // { name: "Offers", href: "#", icon: RocketLaunchIcon, current: false },
  // { name: "Customers", href: "#", icon: FolderIcon, current: false },
];
const userNavigation = [{ name: "Your profile", href: "#" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentView, setCurrentView] = useState("Orders");
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const {
    connect,
    conversations,
    sendMessage,
    activeConversation,
    setActiveConversation,
    activeOrders,
    setActiveOrders,
  } = useConversations();

  useEffect(() => {
    const view = navigation.find((item) => item.href === location.pathname);
    if (view) {
      navigation.forEach((item) => {
        item.current = item.name === view.name;
      });
      setCurrentView(view.name);
    }
  }, [location.pathname]);

  const setActiveView = (e, view) => {
    e.preventDefault();
    navigation.forEach((item) => {
      item.current = item.name === view.name;
    });
    setCurrentView(view.name);
    navigate(view.href);
  };

  useEffect(() => {
    const connectSocket = async () => {
      if (isAuthenticated) {
        try {
          // https://auth0.com/docs/quickstart/spa/react/02-calling-an-api#get-an-access-token
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: "openid profile email",
            },
          });
          connect(accessToken);
        } catch (e) {
          console.log(e.message);
        }
      }
    };
    connectSocket();
  }, [isAuthenticated, getAccessTokenSilently, connect]);

  // const showView = () => {
  //   // check the query param
  //   if (currentView === "Inbox") {
  //     return (
  //       <Inbox
  //         conversations={conversations}
  //         onSendMessage={sendMessage}
  //         activeConversation={activeConversation}
  //         setActiveConversation={setActiveConversation}
  //       />
  //     );
  //   } else if (currentView === "Orders") {
  //     return (
  //       <Orders activeOrders={activeOrders} setActiveOrders={setActiveOrders} />
  //     );
  //   } else if (currentView === "OrdersHistory") {
  //     return <OrdersHistory />;
  //   } else {
  //     return (
  //       <Orders activeOrders={activeOrders} setActiveOrders={setActiveOrders} />
  //     );
  //   }
  // };

  return (
    <DashboardContext.Provider
      value={{
        conversations,
        activeConversation,
        activeOrders,
        onSendMessage: sendMessage,
        setActiveConversation,
        setActiveOrders,
      }}
    >
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-charcoal-gray"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-soft-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      {/* <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=white"
                        alt="Your Company"
                      /> */}
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? "bg-soft-white text-peachy-orange"
                                      : "text-charcoal-gray hover:text-charcoal-gray hover:bg-soft-white",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                  onClick={(e) => setActiveView(e, item)}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-charcoal-gray"
                                        : "text-charcoal-gray group-hover:text-charcoal-gray",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-soft-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=white"
                alt="Your Company"
              /> */}
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          onClick={(e) => setActiveView(e, item)}
                          className={classNames(
                            item.current
                              ? "bg-peachy-orange text-charcoal-gray"
                              : "text-charcoal-gray hover:text-charcoal-gray hover:bg-soft-white",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-charcoal-gray"
                                : "text-charcoal-gray group-hover:text-charcoal-gray",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="mt-auto">
                  <LanguageSelector />
                  <a className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-charcoal-gray hover:bg-soft-white hover:text-charcoal-gray">
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-charcoal-gray group-hover:text-charcoal-gray"
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch justify-end lg:gap-x-6">
              {/* <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                />
              </form> */}
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>

                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {user && user.name}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        <button
                          className="block px-3 py-1 text-sm leading-6 text-gray-900"
                          onClick={() =>
                            logout({
                              logoutParams: {
                                returnTo: window.location.origin,
                              },
                            })
                          }
                        >
                          Log Out
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="h-full">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </DashboardContext.Provider>
  );
}
