import React, { useState } from "react";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

const Conversation = ({ activeConversation, sendMessage, backToList }) => {
  const handleSendMessage = (message) => {
    sendMessage(message, activeConversation.customerWhatsAppNumber);
  };

  // Filter messages for the active chat
  const activeChatMessages = activeConversation.messages;

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between bg-white px-4 py-2">
        <button className="text-gray-700" onClick={backToList}>
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
      </div>
      <MessageList messages={activeChatMessages} />
      <MessageInput onSendMessage={handleSendMessage} />
    </div>
  );
};

export default Conversation;
