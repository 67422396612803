import React, { useEffect } from "react";
import { useExternalScript } from "./../../../hooks/useExternalScript";

import { useParams, useSearchParams } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

import { getOrderById } from "./../../../api/orders";

const successComponent = () => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Order completed, go back to WhatsApp
          </h3>
        </div>
      </div>
    </div>
  );
};

const errorComponent = () => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Payment failed, please try again
          </h3>
        </div>
      </div>
    </div>
  );
};

export default function Completed() {
  let [searchParams] = useSearchParams();
  const [status, setStatus] = React.useState("");
  const [checkoutStatus, setCheckoutStatus] = React.useState(""); // todo should be [
  const [order, setOrder] = React.useState(null); // todo should be []
  const [items, setItems] = React.useState([]);
  const params = useParams();
  // get order id from query params orderId
  const checkoutId = searchParams.get("id");
  console.log("params", params);

  const showResponseStatus = () => {
    if (status === "success") {
      return successComponent();
    } else if (status === "error") {
      return errorComponent();
    }
  };

  const orderIsPaid = () => {
    return order && order.checkout.status === "PAID";
  };

  useEffect(() => {
    // get order by orderId
    const getOrder = async () => {
      const orderId = params.orderId;
      if (!orderId) {
        return;
      }
      const order = await getOrderById(orderId);
      if (!order || !order.items) {
        return;
      }
      setOrder(order);
      console.log("order", order);
    };
    getOrder();
  }, [params.orderId]);

  return (
    <div>
      <div
        id="order-details"
        className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center space-y-6 bg-grey-500"
      >
        <h2 className="text-lg font-medium text-gray-900">Order summary</h2>
        <ul className="divide-y divide-gray-200">
          {order &&
            order.items.map((item) => (
              <li
                key={item.product_retailer_id}
                className="flex justify-center gap-x-6 py-5"
              >
                <div className="flex min-w-0 gap-x-4">
                  {/* <img
                  className="h-12 w-12 flex-none rounded-full bg-gray-50"
                  src={item.image}
                  alt=""
                /> */}
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900 text-center">
                      {item.name} x {item.quantity}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {/* &euro;{item.price} */}
                    </p>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        Total amount: &euro; {order && order.total}
      </div>
      {<div>{!orderIsPaid() && showResponseStatus()}</div>}
      {orderIsPaid() && <div>{successComponent()}</div>}
    </div>
  );
}
