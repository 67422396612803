// MessageInput component
import { use } from "i18next";
import React, { useEffect, useState } from "react";
const MessageInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && message) {
      onSendMessage(message);
      setMessage("");
    }
  };

  const sendMessage = () => {
    onSendMessage(message);
    setMessage("");
  };

  return (
    <div className="flex items-center  py-2">
      <input
        type="text"
        className="block  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-charcoal-gray-600 sm:text-sm sm:leading-6"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyPress}
      />
      <button
        className="flex-shrink-0 bg-soft-white hover:bg-soft-white border-charcoal-gray-500 hover:border-charcoal-gray-700 text-sm border-4 text-charcoal-gray py-1 px-2 rounded"
        type="button"
        onClick={sendMessage}
        disabled={!message}
      >
        send
      </button>
    </div>
  );
};

export default MessageInput;
