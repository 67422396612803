import React from "react";

const DashboardContext = React.createContext({
  conversations: [],
  activeConversation: null,
  activeOrders: [],
  onSendMessage: () => {},
  setActiveConversation: () => {},
  setActiveOrders: () => {},
});

export default DashboardContext;
