import React, { useEffect } from "react";
import { useExternalScript } from "./../../../hooks/useExternalScript";

import { useSearchParams } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

import { getOrderByCheckoutId } from "./../../../api/orders";

const successComponent = () => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Order completed, go back to WhatsApp
          </h3>
        </div>
      </div>
    </div>
  );
};

const errorComponent = () => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Payment failed, please try again
          </h3>
        </div>
      </div>
    </div>
  );
};

export default function SumUp() {
  // todo based on checkout ID, I should get the producs of the order
  const externalScript =
    "https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js";
  const sumup = useExternalScript(externalScript);
  let [searchParams] = useSearchParams();
  const [status, setStatus] = React.useState("");
  const [checkoutStatus, setCheckoutStatus] = React.useState(""); // todo should be [
  const [order, setOrder] = React.useState(null); // todo should be []
  const [items, setItems] = React.useState([]);
  // get order id from query params orderId
  const checkoutId = searchParams.get("id");

  const showResponseStatus = () => {
    if (status === "success") {
      return successComponent();
    } else if (status === "error") {
      return errorComponent();
    }
  };

  const orderIsPaid = () => {
    return order && order.checkout.status === "PAID";
  };

  useEffect(() => {
    if (sumup === "ready") {
      window.SumUpCard.mount({
        id: "sumup-card",
        checkoutId,
        onLoad: function (a) {},
        onResponse: function (type, body) {
          console.log("Type", type);
          console.log("Body", body);
          setStatus(type);
          // Type can be sent, invalid, error, success
        },
        onPaymentMethodsLoad: function (paymentMethods) {
          console.log("Payment methods", paymentMethods);
          return paymentMethods;
        },
      });
    }
  }, [sumup, checkoutId]);

  useEffect(() => {
    // get order by checkoutId
    const getOrder = async () => {
      if (!checkoutId) {
        return;
      }
      const order = await getOrderByCheckoutId(checkoutId);
      if (!order || !order.items) {
        return;
      }
      setOrder(order);
      console.log("order", order);
    };
    getOrder();
  }, [checkoutId]);

  return (
    <div>
      <div
        id="order-details"
        className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center space-y-6 bg-grey-500"
      >
        <h2 className="text-lg font-medium text-gray-900">Order summary</h2>
        <ul className="divide-y divide-gray-200">
          {order &&
            order.items.map((item) => (
              <li
                key={item.product_retailer_id}
                className="flex justify-center gap-x-6 py-5"
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900 text-center">
                      {item.name} x {item.quantity}
                    </p>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        Total amount: &euro; {order && order.total}
      </div>
      {sumup === "loading" && <p>Loading...</p>}
      {sumup === "ready" && (
        <div
          id="sumup-card"
          className={
            status === "success" || (order && order.status === "PAID")
              ? "hidden"
              : ""
          }
        ></div>
      )}
      {<div>{!orderIsPaid() && showResponseStatus()}</div>}
      {orderIsPaid() && <div>{successComponent()}</div>}
    </div>
  );
}
