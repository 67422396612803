import { EnvelopeIcon, PhoneIcon, UserIcon } from "@heroicons/react/20/solid";

import {
  getOrders,
  setOrderCompleted,
  refundOrder as refundOrderAPI,
} from "../api/orders";
import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import ModalOrder from "./OrderModals";
import RefundModal from "./RefundModal";
import DashboardContext from "../context/DashboardContext";
import { DateTime } from "luxon";

export default function Orders() {
  const { getAccessTokenSilently } = useAuth0();
  const { activeOrders, setActiveOrders } = useContext(DashboardContext);
  const [activeOrderComplete, setActiveOrderComplete] = useState(null);
  const [refundOrder, setRefundOrder] = useState(null); // [orderId, setOrderId
  const [modalOrderOpen, setModalOrderOpen] = useState(false);
  const [modalRefundOpen, setModalRefundOpen] = useState(false);
  const { t, i18n } = useTranslation();
  console.log("activeOrders", activeOrders);
  // Filter order based on the status
  let completedOrders = activeOrders.filter(
    (order) => order.status === "COMPLETED"
  );
  completedOrders = completedOrders.map((order) => {
    const pickupDate = DateTime.fromISO(order.pickup.customerPickup);
    return {
      ...order,
      pickup: {
        ...order.pickup,
        customerPickup: pickupDate.toLocaleString(DateTime.DATETIME_SHORT),
      },
    };
  });
  const activeOrdersFiltered = activeOrders.filter(
    (order) => order.status !== "COMPLETED"
  );

  // can you remove duplicates from activeOrdersFiltered based on order._id?

  const handleOrderCompleted = async () => {
    if (!activeOrderComplete) return;
    // call API to set DB to COMPLETED
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email",
      },
    });
    try {
      await setOrderCompleted(
        accessToken,
        activeOrderComplete._id,
        activeOrderComplete.restaurant.id,
        activeOrderComplete.customer.phoneNumber
      );
      setModalOrderOpen(false);

      setActiveOrders((prevState) =>
        prevState.map((order) => {
          if (order._id === activeOrderComplete._id) {
            order.pickup.customerPickup = new Date();
            order.status = "COMPLETED";
          }
          return order;
        })
      );
    } catch (err) {
      console.log("handleOrderCompleted", err);
    }
  };

  const handleRefundOrder = async () => {
    if (!refundOrder) return;
    // call API to set DB to COMPLETED
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email",
      },
    });
    try {
      const resturantId = refundOrder.restaurant.id;
      const checkoutId = refundOrder.checkout.id;
      const orderId = refundOrder._id;
      console.log("refundOrder", refundOrder);
      if (!resturantId || !checkoutId || !orderId) {
        console.log("Error: resturantId or checkoutId is null");
        return null;
      }
      await refundOrderAPI(accessToken, checkoutId, resturantId, orderId);
      setModalRefundOpen(false);
    } catch (err) {
      console.log("handleOrderCompleted", err);
    }
  };

  const handleModalOrderOpen = (order) => {
    setModalOrderOpen(true);
    setActiveOrderComplete(order);
  };

  const handleModalRefundOpen = (order) => {
    setModalRefundOpen(true);
    setRefundOrder(order);
  };

  useEffect(() => {
    async function fetchData() {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email",
        },
      });
      const activeOrders = await getOrders(accessToken);
      setActiveOrders(activeOrders);
    }
    fetchData();
  }, [getAccessTokenSilently, setActiveOrders]);

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          Active Orders
        </h1>
      </div>
      {activeOrdersFiltered && activeOrdersFiltered.length === 0 && (
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                There no active active orders at the moment.
              </p>
            </div>
          </div>
        </div>
      )}
      <ul className="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {activeOrdersFiltered &&
          activeOrdersFiltered.map((order) => (
            <li
              key={order._id}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div className="flex w-full items-center  space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center justify-between space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                      Customer: {order.customer?.customerName}
                    </h3>
                    <div className="flex">
                      <PhoneIcon
                        className=" h-5 w-5 mr-1 text-gray-400"
                        aria-hidden="true"
                      />
                      <span> +{order.customer?.phoneNumber}</span>
                    </div>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500">Status:</span>{" "}
                    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {order.status}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500">Total:</span>{" "}
                    <span className="inline-flex flex-shrink-0 items-center  px-1.5 py-0.5 text-xs font-medium ">
                      &euro;{order.total}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full space-x-6 p-6">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  Items
                </h3>
                <ul className="divide-y divide-gray-200 border-gray-200 text-sm font-medium text-gray-500">
                  {order &&
                    order.items.map((product) => (
                      <li key={product.name} className="flex space-x-6 py-6">
                        <div className="flex-auto space-y-1">
                          <h3 className="text-gray-900">
                            {product.name} x {product.quantity}
                          </h3>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="flex flex-col w-full space-x-6 p-6">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  Pick Up
                </h3>
                {order?.pickup && (
                  <div className="flex items-center space-x-3 flex-col">
                    <span className="text-sm text-gray-500">Pin Code:</span>{" "}
                    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-soft-white px-1.5 py-0.5 text-xs font-medium text-charcoal-gray-700 ring-1 ring-inset ring-charcoal-gray-600/20">
                      {order?.pickup?.pinCode}
                    </span>
                    <span className="text-sm text-gray-500">Time:</span>{" "}
                    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-soft-white px-1.5 py-0.5 text-xs font-medium text-charcoal-gray-700 ring-1 ring-inset ring-charcoal-gray-600/20">
                      {order?.pickup?.time}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col space-x-6 p-6">
                <button
                  disabled={order.status !== "PAID"}
                  onClick={() => handleModalOrderOpen(order)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-charcoal-gray bg-peachy-orange hover:bg-soft-white focus:outline-none"
                >
                  {t("order.orderButtonCompleted")}
                </button>
              </div>
              <div className="flex flex-col w-full space-x-6 p-6">
                {/* <button
                  onClick={() => handleModalRefundOpen(order)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-charcoal-gray bg- hover:bg-charcoal-gray focus:outline-none"
                >
                  {t("order.refundButton")}
                </button> */}
              </div>
            </li>
          ))}
      </ul>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          Completed Orders
        </h1>
      </div>
      <ul className="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {completedOrders &&
          completedOrders.map((order) => (
            <li
              key={order._id}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div className="flex w-full items-center  space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center justify-between space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                      Customer
                    </h3>
                    <div className="flex">
                      <PhoneIcon
                        className=" h-5 w-5 mr-1 text-gray-400"
                        aria-hidden="true"
                      />
                      <span> +{order.customer?.phoneNumber}</span>
                    </div>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500">Status:</span>{" "}
                    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {order.status}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500">Total:</span>{" "}
                    <span className="inline-flex flex-shrink-0 items-center  px-1.5 py-0.5 text-xs font-medium ">
                      &euro;{order.total}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full space-x-6 p-6">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  Items
                </h3>
                <ul className="divide-y divide-gray-200 border-gray-200 text-sm font-medium text-gray-500">
                  {order &&
                    order.items.map((product, index) => (
                      <li
                        key={`${product.name}-${index}`}
                        className="flex space-x-6 py-6"
                      >
                        <div className="flex-auto space-y-1">
                          <h3 className="text-gray-900">
                            {product.name} x {product.quantity}
                          </h3>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="flex flex-col w-full space-x-6 p-6">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  Pick Up
                </h3>
                {order?.pickup && (
                  <div className="flex items-center space-x-3 flex-col">
                    <span className="text-sm text-gray-500">Pin Code:</span>{" "}
                    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-soft-white px-1.5 py-0.5 text-xs font-medium text-charcoal-gray-700 ring-1 ring-inset ring-charcoal-gray-600/20">
                      {order?.pickup?.pinCode}
                    </span>
                    <span className="text-sm text-gray-500">
                      Customer Pickup:
                    </span>{" "}
                    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-soft-white px-1.5 py-0.5 text-xs font-medium text-charcoal-gray-700 ring-1 ring-inset ring-charcoal-gray-600/20">
                      {order?.pickup?.customerPickup}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full space-x-6 p-6">
                {/* <button
                  onClick={() => handleModalRefundOpen(order)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-charcoal-gray bg-peachy-orange hover:bg-soft-white focus:outline-none"
                >
                  {t("order.refundButton")}
                </button> */}
              </div>
            </li>
          ))}
      </ul>
      <ModalOrder open={modalOrderOpen} setOpen={handleOrderCompleted} />
      <RefundModal open={modalRefundOpen} setOpen={handleRefundOrder} />
    </>
  );
}
