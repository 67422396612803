import React, { useContext, useState } from "react";
import Conversation from "./Conversation";
import ConversationList from "./ConversationList";
import { useTranslation } from "react-i18next";
import {
  sendMessage,
  updateConversationUnreadMessages,
} from "../../api/conversations";
import DashboardContext from "../../context/DashboardContext";

import { useAuth0 } from "@auth0/auth0-react";

const Inbox = () => {
  const {
    conversations,
    activeConversation,
    setActiveConversation,
    onSendMessage,
  } = useContext(DashboardContext);
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const handleSendMessage = async (message, to) => {
    // shouldn't I use the socket here? No need for JWT
    onSendMessage(message);
  };

  const handleActiveConversation = async (conversation) => {
    const newActiveConversation = {
      ...conversation,
      unreadMessages: false,
    };
    setActiveConversation(newActiveConversation);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email",
      },
    });
    updateConversationUnreadMessages(accessToken, conversation.id, false);
  };

  const backToList = () => {
    setActiveConversation(null);
  };

  return (
    <div className="flex h-[calc(100vh-64px)]">
      {/* <div className="w-full h-full lg:w-1/2 border-r border-gray-200 overflow-y-auto"> */}
      <div
        className={`w-full lg:w-1/3 ${activeConversation && "hidden lg:block"}`}
      >
        <ConversationList
          conversations={conversations}
          setActiveConversation={handleActiveConversation}
        />
      </div>
      {activeConversation && activeConversation.messages && (
        <div
          className={`w-full lg:w-2/3 ${
            activeConversation ? "block" : "hidden lg:block"
          }`}
        >
          <Conversation
            backToList={backToList}
            messages={activeConversation.messages}
            activeConversation={activeConversation}
            sendMessage={handleSendMessage}
          />
        </div>
      )}
      {!activeConversation && conversations && conversations.length > 0 && (
        <div
          className={`w-full lg:w-2/3 ${
            activeConversation ? "block" : "hidden lg:block"
          }`}
        >
          <p> {t("inbox.selectChat")} </p>
        </div>
      )}
    </div>
  );
};

export default Inbox;
